import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from "react";
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import '../inventory.css';



/**
 * 
 * @param {*} props my_ei (optional) existing item in the cart
 * @param {*} props item  (required) item to be added to the cart
 * @param {*} props handleCartAdd (required) function to add item to the cart
 * @param {*} props onHide (required) function to hide the modal
 * @param {*} props my_qty (optional) suggested quantity from restock
 * @param {*} props saveButtonText (optional) text to display on the save button
 * @returns 
 */
export const ItemCartTab = function (props) {

    var sku_use = !props.my_ei || props.my_ei == "" ? props.item.item_sku : props.my_ei.sku
    var qty_use = (!props.my_ei && props.my_qty) ? props.my_qty.ecom_to_order : !props.my_ei || props.my_ei == "" ? "" : props.my_ei.qty

    var fba_qty_use = (!props.my_ei && props.my_qty) ? props.my_qty.fba_to_order : !props.my_ei || props.my_ei == "" ? "" : props.my_ei.fba_qty
    var cust_qty_use = !props.my_ei || props.my_ei == "" ? "" : props.my_ei.cust_qty

    var order_cust_qty = React.createRef();
    var order_fba_qty = React.createRef();
    var order_qty = React.createRef();
    var order_sku = React.createRef();

    const [order_cust_qty_live, set_order_cust_qty_live] = useState(0)
    const [order_qty_live, set_order_qty_live] = useState(qty_use ? qty_use : 0)
    const [order_fba_qty_live, set_order_fba_qty_live] = useState(fba_qty_use ? fba_qty_use : 0)


    const [errorMessage, setErrorMessage] = useState("");

    if (sku_use) {
        /*var n = sku_use.indexOf("map");
        if (n > 0) {
            sku_use = sku_use.substring(0, n)
        }
        n = sku_use.indexOf("swat");
        if (n > 0) {
            sku_use = sku_use.substring(0, n)
        }*/
    }

    React.useEffect(() => {
        order_sku.value = sku_use
    }, [props.item.item_sku]);

    const my_handleSubmit = (event, isCustom) => {
        event.preventDefault();


        if (order_sku.value == "") {
            setErrorMessage("Error! SKU cannot be empty!")
            return;
        }

        if (!(order_qty.value != "" || order_cust_qty.value != "" || order_fba_qty.value != "")) {
            setErrorMessage("Error! Must Enter At Least One Quantity")
            return;
        } else {

            if (order_cust_qty.value == '') {
                order_cust_qty.value = 0
            }

            if (order_fba_qty.value == '') {
                order_fba_qty.value = 0
            }

            if (order_qty.value == '') {
                order_qty.value = 0
            }
            props.onHide()
            if (props.item.components && props.item.components.length > 0) {
                props.item.components.forEach(comp => {
                    props.handleCartAdd({ 'sku': comp.item_sku,'id': comp.child_group_id, 'qty': order_qty.value, 'fba_qty': order_fba_qty.value, 'cust_qty': order_cust_qty.value, 'ecommerce': !isCustom, 'item_img_url': comp.item_img_url, 'item_cost': comp.cost_price, 'buy_pack_qty': comp.buy_pack_qty, 'sale_pack_qty': comp.sale_pack_qty }, props.my_ei)

                }
                )

            } else {
                console.log(props.item)
                props.handleCartAdd({ 'sku': order_sku.value,'id':  props.item.group_id, 'qty': order_qty.value, 'fba_qty': order_fba_qty.value, 'cust_qty': order_cust_qty.value, 'ecommerce': !isCustom, 'item_img_url': props.item.item_img_url, 'item_cost': props.item.cost_price, 'buy_pack_qty': props.item.buy_pack_qty, 'sale_pack_qty': props.item.sale_pack_qty }, props.my_ei)
            }
        }
    }
    return (
        <div>
            <Modal.Body className="ModalBody">
                <Container fluid>
                    <Row>
                        <Col xs={9} style={{ paddingLeft: 0 }}>
                            <h3>{props.item.item_sku}</h3>
                        </Col>

                        <Col xs={3}>
                            {props.item.item_url && <a className="btn btn-info" href={props.item.item_url} target="_blank">Order Link</a>}
                        </Col>
                    </Row>
                </Container>

                <Form  >
                    {props.item.item_notes && props.item.item_notes.length > 0 && <Row>
                        <Form.Group as={Col} sm={12} controlId="formValueNotes">
                            <Row>
                                <Form.Label style={{ marginBottom: '.35rem' }}>Item Notes</Form.Label>
                            </Row>
                            <Row style={{ paddingLeft: 10, paddingBottom: 5 }}>
                                <Form.Text style={{ whiteSpace: "pre-wrap", marginTop: 0 }}>{props.item.item_notes}</Form.Text>
                            </Row>
                        </Form.Group>
                    </Row>}
                    <Row>
                        <Col xs={12}>
                            <Form.Group controlId="formValue">
                                <Form.Label>Order SKU</Form.Label>
                                <Form.Control disabled={props.item.components && props.item.components.length > 0} autoFocus ref={(input) => order_sku = input} type="string" placeholder="SKU" defaultValue={sku_use} />
                                <Form.Text muted> <FontAwesomeIcon className="trashIcon" icon={faLightbulb} style={{ color: "#e4be01" }} /> &nbsp;Enter comments/notes in parenthesis. They will be visible on all internal pages, but will not appear on the printed Purchase Order. </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6}>
                            <Form.Group controlId="formValue2">
                                <Form.Label>{props.item.custom && props.item.custom == 1 ? "Stock Quantity" : "E-Commerce Quantity"}</Form.Label>
                                <Form.Control disabled={props.item.dnr_ecom} onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        my_handleSubmit(event, props.item.custom && props.item.custom == 1);
                                    }
                                }} onChange={() => set_order_qty_live(order_qty.value ? order_qty.value : 0)} autoFocus ref={(input) => order_qty = input} type="number" placeholder="##" defaultValue={qty_use} min="0" />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6}>
                            <Form.Group controlId="formValue2b">
                                <Form.Label>FBA Quantity</Form.Label>
                                <Form.Control disabled={props.item.dnr_fba} onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        my_handleSubmit(event, props.item.custom && props.item.custom == 1);
                                    }
                                }} onChange={() => set_order_fba_qty_live(order_fba_qty.value ? order_fba_qty.value : 0)} ref={(input) => order_fba_qty = input} type="number" placeholder="##" defaultValue={fba_qty_use} min="0" />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6}>
                            <Form.Group controlId="formValue2c">
                                <Form.Label>Customer Repair Quantity</Form.Label>
                                <Form.Control onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        my_handleSubmit(event, props.item.custom && props.item.custom == 1);
                                    }
                                }} onChange={() => set_order_cust_qty_live(order_cust_qty.value ? order_cust_qty.value : 0)} ref={(input) => order_cust_qty = input} type="number" placeholder="##" defaultValue={cust_qty_use} min="0" />
                            </Form.Group>
                        </Col>
                    </Row>

                    {(props.item.dnr_ecom || props.item.dnr_fba) &&
                        <Row>
                            <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as <em>Do Not Reorder {props.item.dnr_fba && !props.item.dnr_ecom ? " For FBA" : ""}</em>. If you need to re-order this item {props.item.dnr_fba && !props.item.dnr_ecom ? " for FBA" : ""}, unmark the <em>Do Not Reorder</em> status on the 'Item Details' tab. </Form.Text>
                        </Row>
                    }

                    {props.item.buy_pack_multiple > 1 && ((props.item.sale_pack_qty * (parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)))) % props.item.buy_pack_multiple != 0 &&
                        <Row style={{ marginBottom: 10 }}>
                            <Form.Text style={{ color: "black" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationTriangle} style={{ color: "#f3ad0f" }} /> &nbsp; This item needs to be ordered in multiples of  {props.item.buy_pack_multiple}. </Form.Text>
                        </Row>
                    }

                    {props.item.fba_case_qty > 1 && (props.item.sale_pack_qty * parseInt(order_fba_qty_live)) % props.item.fba_case_qty != 0 &&
                        <Row style={{ marginBottom: 10 }}>
                            <Form.Text style={{ color: "black" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationTriangle} style={{ color: "#f3ad0f" }} /> &nbsp; This item needs to be ordered for FBA in multiples of  {props.item.fba_case_qty}. </Form.Text>
                        </Row>
                    }

                    {props.item.buy_pack_qty > 1 && props.item.sale_pack_qty == 1 &&
                        <Row>
                            <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as being purchased in a package of {props.item.buy_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u>{Math.ceil((parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)) / parseInt(props.item.buy_pack_qty))}</u>, and when this item is received, the quantity will be increased by <u>{(parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live))}</u>  </Form.Text>
                        </Row>
                    }
                    {props.item.sale_pack_qty > 1 && props.item.buy_pack_qty == 1 &&
                        <Row>
                            <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as being re-sold in a bundle of {props.item.sale_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u> {(parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)) * parseInt(props.item.sale_pack_qty)}</u>, but when this item is received, the quantity will be increased by <u>{(parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live))}</u>  </Form.Text>
                        </Row>
                    }
                    {props.item.sale_pack_qty > 1 && props.item.buy_pack_qty > 1 &&
                        <Row>
                            <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as purchased in a packaged of {props.item.buy_pack_qty}, AND being re-sold in a bundle of {props.item.sale_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u>{Math.ceil(((parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)) * parseInt(props.item.sale_pack_qty)) / parseInt(props.item.buy_pack_qty))}</u>, but when this item is received, the quantity will be increased by  <u>{(parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live))}</u>  </Form.Text>
                        </Row>
                    }
                    {props.item.components && props.item.components.length > 0 &&
                        <Row style={{ marginBottom: 10 }}>
                            <Form.Text style={{ color: "black" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationTriangle} style={{ color: "#f3ad0f" }} /> &nbsp; This item has {props.item.components.length} components. Placing an order for this item will instead add the following components to the cart: </Form.Text>
                            {props.item.components.map(component => (
                                <Form.Text key={component.child_group_id} style={{ color: "black", marginLeft: 25 }}><u>{(parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)) * component.quantity}</u>&nbsp; {component.item_name}</Form.Text>
                            ))}
                        </Row>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {errorMessage &&
                    <p className="error"> {errorMessage} </p>}
                <Button onClick={props.onHide}>Cancel</Button>
                <Button type="submit" disabled={(props.item.fba_case_qty > 1 && (props.item.sale_pack_qty * parseInt(order_fba_qty_live)) % props.item.fba_case_qty != 0) || (props.item.buy_pack_multiple > 1 && ((props.item.sale_pack_qty * (parseInt(order_cust_qty_live) + parseInt(order_fba_qty_live) + parseInt(order_qty_live)))) % props.item.buy_pack_multiple != 0)} onClick={(e) => my_handleSubmit(e, props.item.custom && props.item.custom == 1)}>{props.saveButtonText? props.saveButtonText : "Add to Cart"}</Button>
            </Modal.Footer>

        </div>

    )
}