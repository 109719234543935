import React, { Component } from 'react';
import logo from '../logo.svg';
import axios from "axios";
import '../App.css';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import FrontPageSales from './frontPageSales.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HomeChart from './charts/homeChart.js';
import HomeChartRadial from './charts/homeChartRadial.js';
import Spinner from 'react-bootstrap/Spinner';
import Footer from './headers/footer.js'
import Card from 'react-bootstrap/Card';
import { setRestockValues, OUT_OF_STOCK, ORDER_NOW, SEND_TO_FBA } from './Restock/restockValues.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TutorialModal } from './tutorial'
import {withRouter} from './withRouter';


import { faArrowUp, faArrowDown, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

let endpoint = "";
//import ToDoList from "../inventory";



class FrontPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebayTokenValid: true,
      items: [],
      summary: {},
      orderNow: -1,
      itemOutOfStock: -1,
      itemSendToFBA: -1,
      itemsNoProfit: -1,
      itemsNoCost: -1,
      mapViolation:-1,
      amazonWarnings: 0,
      gotData: false,
      sales_data: {},
      storeName: "",
      showTutorial: false

    }
    this.timer = null
    this.sendCode = this.sendCode.bind(this)
    this.getStockStatus = this.getStockStatus.bind(this)

  }


  componentWillUnmount() {
    clearInterval(this.timer);
    if (this.controller) {
      this.controller.abort()
    }
  }
  parseDate(input) {

    let parts = input.split('-');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // Note: months are 0-based
  }
  getItem = () => {
    let currentComponent = this;
    this.getAmazonStatus();
    axios.get(endpoint + "/api/get-sales-graph-data",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
         signal: this.controller.signal
      }).then(res => {
        //console.log(res.data)
        if (res.data) {
          var formatted_data = []
          if (res.data.daily_data) {
            res.data.daily_data.forEach(series => {
              if (series.data_points) {
                formatted_data.push({
                  label: series.data_label,
                  color: series.data_color,
                  data: series.data_points.map(datum => {
                    var newObj = {}
                    newObj['x'] = currentComponent.parseDate(datum.x)
                    newObj['y'] = datum.y
                    return newObj
                  }

                  )
                })
              }
            });
          }
          this.setState({

            items: formatted_data,
            summary: res.data.summary_view,
            sales_data: res.data.sales_numbers,
            gotData: true

          })




        } else {
          currentComponent.setState({
            items: [],

          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        console.log(error)
        if (!error.response || error.response.status == "401") {
          this.props.setLogcallback("", "")
          this.props.logincallback("Error! You have been logged out. Please log back in")
          currentComponent.props.navigate('/index.html')
        }
        currentComponent.setState({
          items: [],
        });

      })
  };


  getAmazonStatus = () => {
    let currentComponent = this;
    axios.get(endpoint + "/api/get-amazon-status",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
         signal: this.controller.signal
      }).then(res => {
        //console.log(res.data)
        if (res.data) {
          var counter = 0
          res.data.forEach(item => {
            if (item.count > 5) {
              counter++
            }
          })
          this.setState({
            amazonWarnings: counter
          })
        } else {
          currentComponent.setState({
            amazonWarnings: 0,
          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        if (!error.response || error.response.status == "401") {
          this.props.setLogcallback("", "")
          this.props.logincallback("Error! You have been logged out. Please log back in")
          currentComponent.props.navigate('/index.html')
        }
        currentComponent.setState({
          amazonWarnings: 0
        });
      });
  };


  sendCode(code) {
    axios.post(endpoint + '/api/update-ebay-code',
      code, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      },  signal: this.controller.signal
    })
      .then(function (response) {

        this.props.checkEbay(true);

        //console.log(response.headers);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }
      });
  }

  getStockStatus = () => {
    let currentComponent = this;

    axios.get(endpoint + "/api/get-order-data",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },  signal: this.controller.signal
      }).then(res => {
        if (res.data) {
          var tmpdata = [...res.data]
          tmpdata.map(e => {
            var ret = setRestockValues(e)
            Object.assign(e, { to_order: ret.order })
            Object.assign(e, { status: ret.status })
            Object.assign(e, { daysLeft: ret.days_left })
            Object.assign(e, { status_num: ret.status_num })
          })
          var toOrder = 0;
          var outOfStock = 0
          var sendToFBA = 0
          var noCost = 0
          var noProfit = 0
          var map_violation=0
          tmpdata.forEach(item => {
            if (item.status_num & ORDER_NOW) {
              toOrder += 1
              
            }

            if (item.map_cost > item.web_price_min){
              map_violation+=1
            }

            if (item.status_num & SEND_TO_FBA && item.item_qty_reserved > 0) {
              sendToFBA += 1
            }
            if (item.status_num & OUT_OF_STOCK && item.on_order_qty < 1) {
              outOfStock += 1
            }
            if (item.cost_price == 0) {
              noCost += 1
            }
            var today = new Date()
            if (item.avg_profit < 0 && !item.dnr_ecom && !item.dnr_fba && (item.hide_until == "" || !Date.parse(item.hide_until) || Date.parse(item.hide_until) < today)) {
              noProfit += 1
            }

          })
          this.setState({
            orderNow: toOrder,
            itemOutOfStock: outOfStock,
            itemSendToFBA: sendToFBA,
            itemsNoCost: noCost,
            itemsNoProfit: noProfit,
            mapViolation:map_violation
          });

        } else {
          currentComponent.setState({
            items: [],
            allItems: [],
            errorMessage: "Server Error Fetching Orders"

          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        currentComponent.setState({
          items: [],
          allItems: [],
          errorMessage: "Error Fetching Items. Consider logging back in"
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
          }
        } else {
          console.log(error)
          currentComponent.setState({
            items: [],
            allItems: [],
            errorMessage: "Server Error. Could not fetch orders"
          });
        }
      });
  };

  componentDidMount() {
    this.controller= new AbortController();
    this.setState({ storeName: this.props.storeName })
    this.getItem()
    this.getStockStatus()
    this.getAmazonStatus()
    this.timer = setInterval(() => this.getItem(), 1000 * 60 * 4) 
    //this.timer = setInterval(()=> this.getAmazonStatus(), 1000*50*4
    /*
    if ((String(window.location.href)).includes("code")) {
      this.sendCode({ url: window.location.href })

    }*/

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.storeName != this.props.storeName) {
      this.setState({ storeName: this.props.storeName })
    }
  }

  render() {

    return (
      <div className="App-header">
        <TutorialModal show={this.state.showTutorial} onHide={() => { this.setState({ showTutorial: false }) }} />
        <div className="FrontPageWrapper">
          <h1>{this.state.storeName}</h1>

          {/*<div className="customtable" style={{marginBottom:20}}>
            <h2 className="left-align headerBox">Dashboard Update</h2>
            <div className="SalesStats tablerow" style={{textAlign:"left", paddingLeft:20}}>
              <p style={{fontSize:"1rem", marginBottom:0}}><span>The dashboard was updated to the latest version, version 3.0.0. For a list of changes, </span><span style={{cursor:"pointer"}} onClick={()=>this.setState({showTutorial:true})}> <u>click here</u></span></p>
            </div>
    </div>*/}

          <div className="customtable" style={{ marginBottom: 20 }}>
            <h2 className="left-align headerBox">Overview</h2>
            <div className="SalesStats OverviewStats tablerow">
              {Object.keys(this.state.summary).length > 0 ? <Container fluid>
                <Row>
                  <Col className="TopColumn" style={{ borderRight: "2px solid lightgray", paddingLeft: 30, paddingRight: 30 }}>

                    Open Purchase Orders
                    <Row style={{ textAlign: "center" }}>
                      <span style={{ fontSize: ".8rem", color: "gray", width: "100%" }}>Orders created more than 30 days ago</span>
                    </Row>
                    <Row className="text-start">
                      <Link className="homeAlerts" to="/past-orders?filter=PLACED&activeFilter=3&dateFilter=true"><span style={this.state.summary.placed_orders > 0 ? { color: "red" } : { color: "green" }}>{this.state.summary.placed_orders}</span> Old Unreceived Orders </Link>
                    </Row>
                    <Row className="text-start">
                      <Link className="homeAlerts" to="/past-orders?filter=PARTIAL_RECEIVED&activeFilter=4&dateFilter=true"><span style={this.state.summary.partial_orders > 0 ? { color: "red" } : { color: "green" }}>{this.state.summary.partial_orders}</span> Old Partially Received Orders </Link>
                    </Row>
                  </Col>
                  {this.state.orderNow > 0 ?
                    <Col className="TopColumn MidTopColumn" style={{ borderRight: "2px solid lightgray", paddingLeft: 30, paddingRight: 30 }}>

                      Stock Status
                      <Row className="text-start">
                        <Link to='/item-select?showOrderNow=true&restock=true' className="homeAlerts"><span style={this.state.orderNow > 0 ? { color: "blue" } : { color: "green" }}>{this.state.orderNow}</span> Items to Order Now </Link>
                      </Row>
                      <Row className="text-start">
                        <Link to='/item-select?showOutOfStock=true&restock=true&onlyNoneOnOrder=true' className="homeAlerts"><span style={this.state.itemOutOfStock > 0 ? { color: "blue" } : { color: "green" }}>{this.state.itemOutOfStock}</span> Items Out of Stock </Link>

                      </Row>
                      <Row className="text-start">
                        <Link to='/item-select?showSendToFBA=true&restock=true' className="homeAlerts"><span style={this.state.itemSendToFBA > 0 ? { color: "blue" } : { color: "green" }}>{this.state.itemSendToFBA}</span> Items To Send to FBA </Link>
                      </Row>
                    </Col> :
                    <Col>
                      <Spinner animation="border" />
                    </Col>
                  }
                  {this.state.orderNow > 0 ?
                    <Col className="TopColumn" style={{ paddingLeft: 30, paddingRight: 30 }}>

                      Inventory Alerts
                      <Row className="text-start">

                      <Link to='/inventory?missingCost=true' className="homeAlerts"><span style={this.state.itemsNoCost > 0 ? { color: "red" } : { color: "green" }}>{this.state.itemsNoCost}</span> Items Missing Cost Price </Link>
                      </Row>
                      <Row className="text-start">
                        <Link to='/item-select?onlyNegativeProfit=true' className="homeAlerts"><span style={this.state.itemsNoProfit > 0 ? { color: "red" } : { color: "green" }}>{this.state.itemsNoProfit}</span> Items With Negative Avg Profit </Link>

                      </Row>

                      <Row className="text-start">
                        <Link to='/inventory?onlyMapViolation=true' className="homeAlerts"><span style={this.state.mapViolation > 0 ? { color: "red" } : { color: "green" }}>{this.state.mapViolation}</span> Item{this.state.mapViolation ==1 ? "" : "s"} With MAP violations </Link>

                      </Row>

                      
                      {this.state.amazonWarnings > 0 &&

                        <Row className="text-start">
                          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "red", fontSize: ".7em", marginRight: 5 }} />
                          <Link to='/amazon-offset' className="homeAlerts"><span style={this.state.amazonWarnings > 0 ? { color: "red" } : { color: "green" }}>{this.state.amazonWarnings}</span> Amazon Inventory Errors </Link>

                        </Row>}

                    </Col> :
                    <Col>
                      <Spinner animation="border" />
                    </Col>
                  }
                </Row>
              </Container> : <Spinner animation="border" />}
            </div>
          </div>
          <FrontPageSales data={this.state.sales_data} />
          {(Object.keys(this.state.summary).length < 1 || this.state.summary.has_financials) &&
            <div className="customtable">
              <h2 className="left-align headerBox">Profit Summary</h2>
              <div className="SalesStats tablerow CollapseOnSmall">

                {Object.keys(this.state.summary).length > 0 ?


                  <Container fluid>
                    <Row>
                      <Col className="PieColumn" style={{ borderRight: "2px solid lightgray", paddingLeft: 30, paddingRight: 30 }} >
                        Yesterday
                        <div className="PieWrapper">

                          <div className="dot" title={`Profit - Yesterday: $${this.state.summary.today_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}, 2 days ago: $${this.state.summary.yesterday_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}\nRevenue - Yesterday: $${this.state.summary.today_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}, 2 days ago: $${this.state.summary.yesterday_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}>
                            <Row>
                              <small className="salesFigures sfLarge">Profit</small>
                            </Row>
                            <Row>
                              <small className="salesFigures">{
                                this.state.summary.today_profit >= this.state.summary.yesterday_profit ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.today_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })} </small></Row>
                            <Row>
                              <small className="salesFigures sfLarge">Revenue</small>
                            </Row>
                            <Row>
                              <small className="salesFigures">{
                                this.state.summary.today_revenue >= this.state.summary.yesterday_revenue ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.today_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })} </small>
                            </Row>
                          </div>
                          <HomeChartRadial data={this.state.summary.today_data} />
                        </div>
                      </Col>
                      <Col className="PieColumn PieMidColumn" style={{ borderRight: "2px solid lightgray", paddingLeft: 30, paddingRight: 30 }} >
                        This Week
                        <div className="PieWrapper"
                        >

                          <div className="dot" title={`Measured over periods of 7 days.\nProfit - This Week: $${this.state.summary.week_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}, Last Week: $${this.state.summary.lastweek_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}\nRevenue - This Week: $${this.state.summary.week_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}, Last Week: $${this.state.summary.lastweek_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}`} >
                            <Row>
                              <small className="salesFigures sfLarge">Profit</small>
                            </Row>
                            <Row>
                              <small className="salesFigures"> {
                                this.state.summary.week_profit >= this.state.summary.lastweek_profit ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.week_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })} </small></Row>

                            <Row>
                              <small className="salesFigures sfLarge">Revenue</small>
                            </Row> <Row>
                              <small className="salesFigures"> {
                                this.state.summary.week_revenue >= this.state.summary.lastweek_revenue ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.week_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })} </small>
                            </Row>
                          </div>
                          <HomeChartRadial data={this.state.summary.week_data} />
                        </div>
                      </Col>
                      <Col className="PieColumn" style={{ paddingLeft: 30, paddingRight: 30 }} >
                        This Month
                        <div
                          className="PieWrapper">

                          <div className="dot" title={`Measured over periods of 30 days.\nProfit - This Month: $${this.state.summary.month_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}, Last Month: $${this.state.summary.lastmonth_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}\nRevenue - This Month: $${this.state.summary.month_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}, Last Month: $${this.state.summary.lastmonth_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}`} >
                            <Row>
                              <small className="salesFigures sfLarge">Profit</small>
                            </Row>
                            <Row>
                              <small className="salesFigures">{
                                this.state.summary.month_profit >= this.state.summary.lastmonth_profit ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.month_profit.toLocaleString(undefined, { minimumFractionDigits: 2 })}</small>
                            </Row>
                            <Row>
                              <small className="salesFigures sfLarge">Revenue</small>
                            </Row>
                            <Row>
                              <small className="salesFigures">{
                                this.state.summary.month_revenue >= this.state.summary.lastmonth_revenue ?
                                  <FontAwesomeIcon className="frontArrow positiveArrow" icon={faArrowUp} /> :
                                  <FontAwesomeIcon className="frontArrow negativeArrow" icon={faArrowDown} />
                              }
                                ${this.state.summary.month_revenue.toLocaleString(undefined, { minimumFractionDigits: 2 })} </small>
                            </Row>
                          </div>
                          <HomeChartRadial data={this.state.summary.month_data} />
                        </div>
                      </Col>
                    </Row>
                  </Container>



                  : <Spinner animation="border" />}
              </div>
            </div>}

          {this.state.items.length > 0 || !this.state.gotData ? <div className="customtable" style={{ marginTop: 20 }}>

            <h2 className="left-align headerBox"> Daily Profit</h2>
            <div className="tablerow" style={{ paddingBottom: 75, marginBottom: 10 }}>
              {this.state.items.length > 0 ?
                <HomeChart items={this.state.items} />
                : <Spinner animation="border" />
              }
            </div>


          </div> : <div></div>}
        </div>
        <Footer />
      </div>







    )
  }
}

export default withRouter(FrontPage);

/*
 
*/