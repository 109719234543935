import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from "react";
import { Tags } from "../tags.js";
import { v4 as uuidv4 } from 'uuid';
import { IndeterminateCheckbox } from '../inderterminateCheckbox.js'
import EnsureModal from '../ensureModal.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import '../inventory.css';
import { updateItemProperties, updateItemQtyRemote, faExclamationTriangle } from "./utilFunctions";


const checkTagEquality = (tl1, tl2) => {

    if (tl1.length != tl2.length) {
        return false
    }
    for (var i = 0; i < tl1.length; i++) {
        if (tl1[i].id != tl2[i].id) {
            return false
        }
    }
    return true
}
/**
 * 
 * @param {*} props.updateItemQty (optional) function to call after updating the item qty ONLY
 * @param {*} props.item (required) 
 * @param {*} props.onHide (required) function to hide modal
 * @param {*} props.my_updateItem (optional) function to call after updating the item
 * @param {*} props.allTags (required) list of all tags
 * @param {*} props.readOnly (required) whether or not the modal is read only
 * @returns 
 */
export const ItemPropertiesTab = function (props) {
    var qty_val = React.createRef();
    var url_val = React.createRef();
    var barcode_val = React.createRef();
    var cost_val = React.createRef();
    var notes_val = React.createRef();

    var part_num_val = React.createRef();
    var warehouse_loc = React.createRef();
    var overstock_loc = React.createRef();
    var item_name_val = React.createRef();
    var fba_hold_val = React.createRef();
    var item_vendor = React.createRef();
    var item_sku_val = React.createRef();
    var buy_pack_qty = React.createRef();
    var sale_pack_qty = React.createRef();
    var fba_case_qty = React.createRef();
    var order_mutiple = React.createRef();

    var main_id = React.createRef();
    var dnr_ecom = React.createRef();
    var dnr_fba = React.createRef();
    var map_cost_ref = React.useRef(0);
    var hide_ref = React.useRef(false);


    const [ids, setIds] = useState([]);

    const [dnrGroupSum, setDnrGroupSum] = useState(0)
    const [showHideMessage, setShowHideMessage] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)



    const [tags, setTags] = useState([])

    const onDelete = () => {
        setShowDeleteModal(true)
    }
    const onDeleteSure = () => {
        var payload = { updated_field: ['inactive'], updated_item: props.item }
        updateItemProperties(payload.updated_item, payload.updated_field, props.token).then((retVal) => {
            if(props.my_updateItem){
                props.my_updateItem(payload.updated_field, payload.updated_item, retVal)
            }
        })
        props.onHide();
    }

    const my_handleSubmit = (event) => {

        var payload = { updated_field: [], updated_item: props.item }

        var dnr_change = false

        if (qty_val.value != props.item.item_qty) {
            var qtypayload = [{
                item_id: props.item.group_id,
                item_qty_delta: parseInt(qty_val.value) - parseInt(props.item.item_qty),
                item_qty: parseInt(props.item.item_qty)
            }]
            updateItemQtyRemote(qtypayload, props.token).then((retVal) => {
                if(props.updateItemQty){
                    props.updateItemQty(qtypayload, retVal )
                }
            })
            payload.updated_item.item_qty = parseInt(qty_val.value)
        }
        if (url_val.value != props.item.item_url) {
            payload.updated_item.item_url = url_val.value
            payload.updated_field.push('item_url')
        }
        if (barcode_val.value != props.item.item_barcode) {
            payload.updated_item.item_barcode = barcode_val.value
            payload.updated_field.push('item_barcode')
        }
        if (notes_val.value != props.item.item_notes) {
            payload.updated_item.item_notes = notes_val.value
            payload.updated_field.push('item_notes')
        }

        if (main_id && main_id.value && main_id.value.length > 0 && main_id.value[0] != "*") {
            payload.updated_item.primary_item_identifier = main_id.value
            payload.updated_field.push('item_identifiers')

            var tmp = main_id.value.split(':')
            var ids_temp = ids;
            ids_temp.unshift(ids_temp.splice(ids_temp.findIndex(item => item['id_value'] == tmp[1].trim() && item['id_type'] == tmp[0].trim()), 1)[0]);

            ids_temp[0]['id_primary'] = 1

            for (var i = 1; i < ids_temp.length; i++) {
                ids_temp[i]['id_primary'] = 0
            }
            setIds(ids_temp)
            props.item.item_identifiers = JSON.stringify(ids_temp).slice(1, -1)
        }


        var cost_val_use = cost_val.value
        var index = cost_val_use.indexOf("$")
        if (index >= 0) {
            cost_val_use = cost_val_use.substring(index + 1, cost_val.length)
        }
        if (cost_val_use.length == 0) {
            cost_val_use = "0"
        }
        cost_val_use = parseFloat(cost_val_use)

        if (cost_val_use != props.item.cost_price && !Number.isNaN(cost_val_use)) {
            payload.updated_item.cost_price = cost_val_use
            payload.updated_field.push('cost_price')
        }
        if (part_num_val.value != props.item.item_part_num) {
            payload.updated_item.item_part_num = part_num_val.value
            payload.updated_field.push('item_part_num')
        }
        if (warehouse_loc.value != props.item.item_warehouse_loc) {
            payload.updated_item.item_warehouse_loc = warehouse_loc.value
            payload.updated_field.push('item_warehouse_loc')
        }
        if (overstock_loc.value != props.item.item_overstock_loc) {
            payload.updated_item.item_overstock_loc = overstock_loc.value
            payload.updated_field.push('item_overstock_loc')
        }

        if (buy_pack_qty.value != props.item.buy_pack_qty || (props.item.newItem && parseInt(buy_pack_qty.value) <=1)) {
            payload.updated_item.buy_pack_qty = parseInt(buy_pack_qty.value)
            payload.updated_field.push('buy_pack_qty')
        }
        if (sale_pack_qty.value != props.item.sale_pack_qty || (props.item.newItem && parseInt(sale_pack_qty.value) <=1)) {
            payload.updated_item.sale_pack_qty = parseInt(sale_pack_qty.value)
            payload.updated_field.push('sale_pack_qty')
        }
        if (fba_case_qty.value != props.item.fba_case_qty|| (props.item.newItem && parseInt(fba_case_qty.value) <=1)) {
            payload.updated_item.fba_case_qty = parseInt(fba_case_qty.value)
            payload.updated_field.push('fba_case_qty')
        }
        if (item_vendor.value != props.item.item_vendor) {
            payload.updated_item.item_vendor = item_vendor.value
            payload.updated_field.push('item_vendor')
        }

        if (order_mutiple.value != props.item.buy_pack_multiple || (props.item.newItem && parseInt(order_mutiple.value) <=1)) {
            payload.updated_item.buy_pack_multiple = parseInt(order_mutiple.value)
            payload.updated_field.push('buy_pack_multiple')
        }

        var map_cost_val_use = map_cost_ref.current.value
        var index = map_cost_val_use.indexOf("$")
        if (index >= 0) {
            map_cost_val_use = map_cost_val_use.substring(index + 1, map_cost_val_use.length)
        }
        if (map_cost_val_use.length == 0) {
            map_cost_val_use = "0"
        }
        map_cost_val_use = parseFloat(map_cost_val_use)

        if (map_cost_val_use != props.item.map_cost && !Number.isNaN(map_cost_val_use)) {
            payload.updated_item.map_cost = map_cost_val_use
            payload.updated_field.push('map_cost')
        }

        if (dnr_ecom.current.checked != props.item.dnr_ecom) {

            payload.updated_item.dnr_ecom = dnr_ecom.current.checked
            payload.updated_field.push('dnr_ecom')
            dnr_change = true
        }
        if (dnr_fba.current.checked != props.item.dnr_fba) {
            payload.updated_item.dnr_fba = dnr_fba.current.checked
            payload.updated_field.push('dnr_fba')
            dnr_change = true
        }

        if (dnr_change) {
            var index = item_sku_val.value.toLowerCase().indexOf("dnr")
            var index_with_paren = item_sku_val.value.toLowerCase().indexOf(" (dnr)")

            var index_with_paren_fba = item_sku_val.value.toLowerCase().indexOf(" (dnr fba)")



            if (dnr_ecom.current.checked && (index < 0 || index_with_paren_fba > 0)) {
                item_sku_val.value += " (DNR)"
            } else if (!dnr_ecom.current.checked && index_with_paren > 0) {
                item_sku_val.value = item_sku_val.value.substring(0, index_with_paren) + item_sku_val.value.substring(index_with_paren + 6)
            } else if (!dnr_ecom.current.checked && index > 0) {
                item_sku_val.value = item_sku_val.value.substring(0, index) + item_sku_val.value.substring(index + 3)
            }



            if (dnr_fba.current.checked && !dnr_ecom.current.checked && index_with_paren_fba < 0) {
                item_sku_val.value += " (DNR FBA)"
            } else if ((!dnr_fba.current.checked || (dnr_fba.current.checked && dnr_ecom.current.checked)) && index_with_paren_fba > 0) {
                item_sku_val.value = item_sku_val.value.substring(0, index_with_paren_fba) + item_sku_val.value.substring(index_with_paren_fba + 10)
            }
        }

        if (item_name_val.value != props.item.item_name) {
            payload.updated_item.item_name = item_name_val.value
            payload.updated_field.push('item_name')
        }
        if (item_sku_val.value != props.item.item_sku) {
            payload.updated_item.item_sku = item_sku_val.value
            payload.updated_field.push('item_sku')
        }

        if (hide_ref.current.checked && props.item.hide_until == "") {
            payload.updated_item.hide_until = "TRUE"
            payload.updated_field.push('hide_until')
        } else if (!hide_ref.current.checked && props.item.hide_until != "") {
            payload.updated_item.hide_until = ""
            payload.updated_field.push('hide_until')
        }


        if (fba_hold_val.value != props.item.item_qty_reserved) {
            var valUse = parseInt(fba_hold_val.value)
            if (!Number.isNaN(valUse)) {
                payload.updated_item.item_qty_reserved = valUse
                payload.updated_field.push('item_qty_reserved')
            }
        }

        if (!props.item.item_tags || !checkTagEquality(tags, props.item.item_tags)) {
            payload.updated_field.push('item_tags')
            payload.updated_item.item_tags = tags
        }

        if (payload.updated_field.length) {
            updateItemProperties(payload.updated_item, payload.updated_field, props.token).then((retVal) => {
                if(props.my_updateItem){
                    props.my_updateItem(payload.updated_field, payload.updated_item, retVal)
                }
            })
        }

        props.onHide();
    }

    React.useEffect(() => {

        if (props.item) {

            if (props.item.item_identifiers) {
                var tmp = "[" + props.item.item_identifiers + "]"

                tmp = JSON.parse(tmp)

                const uniqueArray = tmp.filter((value, index) => {
                    const _value = JSON.stringify(value);
                    return index === tmp.findIndex(obj => {
                        return JSON.stringify(obj) === _value;
                    });
                });

                setIds(uniqueArray)
            }

            if (props.item.dnr_ecom  || props.item.dnr_fba) {
                setDnrGroupSum(props.item.dnr_ecom + props.item.dnr_fba)
            }
            if (props.item.item_tags) {
                setTags(props.item.item_tags)
            }

            if (props.item.newItem) {
                props.item.item_id = uuidv4()
                props.item.description_id = uuidv4()
                props.item.group_id = uuidv4()
            }

        }
    }, [props.item]);


    return (
        <div>
            <Form onSubmit={my_handleSubmit} className="ItemModalDetailsForm">
            <Modal.Body className="ModalBody">
                <EnsureModal onSubmit={onDeleteSure} show={showDeleteModal} onHide={() => setShowDeleteModal(false)}
                    message="Are you sure you want to delete this custom item?" />
                
                    <Row>

                        <Form.Group as={Col} controlId="formValueName">
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control required ref={(input) => item_name_val = input} disabled={props.readOnly} type="string" defaultValue={props.item.item_name} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue2Sku">
                            <Form.Label>Item SKU</Form.Label>
                            <Form.Control required ref={(input) => item_sku_val = input} disabled={props.readOnly} type="string" defaultValue={props.item.item_sku} />
                        </Form.Group>

                    </Row>

                    <Row>

                        <Form.Group as={Col} controlId="formValue5a">
                            <Form.Label>Part Number</Form.Label>
                            <Form.Control ref={(input) => part_num_val = input} disabled={props.readOnly} placeholder="Part Num" type="string" defaultValue={props.item.item_part_num} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue8">
                            <Form.Label>Purchase Cost</Form.Label>
                            <Form.Control ref={(input) => cost_val = input} disabled={props.readOnly} placeholder="Cost Price" type="string" defaultValue={props.item.cost_price && props.item.cost_price > 0 ? `$${props.item.cost_price.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : ""} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue9">
                            <Form.Label>{props.item.web_price_min == props.item.web_price_max ? "Sale Price" : "Price Range"}</Form.Label>
                            <Form.Control disabled type="string" defaultValue={!props.item.web_price_min ? "" : props.item.web_price_min == props.item.web_price_max ? `$${props.item.web_price_min.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : `$${props.item.web_price_min.toLocaleString(undefined, { minimumFractionDigits: 2 })} - $${props.item.web_price_max.toLocaleString(undefined, { minimumFractionDigits: 2 })}`} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue8">
                            <Form.Label>MAP Cost</Form.Label>
                            <Form.Control ref={map_cost_ref} disabled={props.readOnly} placeholder="MAP Cost" type="string" defaultValue={props.item.map_cost > 0 ? `$${props.item.map_cost.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : ""} />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="formValue7">
                            <Form.Label>Stock Qty</Form.Label>
                            <Form.Control disabled={((!props.item.is_regular && parseInt(props.item.item_qty) ==0)|| props.readOnly) && !props.item.newItem} type="number" min="0" placeholder="Qty" ref={(input) => qty_val = input} defaultValue={parseInt(props.item.item_qty)} />
                        </Form.Group>


                        <Form.Group as={Col} controlId="formValue7a">
                            <Form.Label>FBA Qty</Form.Label>
                            <Form.Control type="number" placeholder="Qty"  min="0" disabled defaultValue={parseInt(props.item.item_qty_incoming)} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue7as">
                            <Form.Label>FBA Hold Qty</Form.Label>
                            <Form.Control type="number" placeholder="FBA Hold Qty"  min="0" disabled={props.readOnly} ref={(input) => fba_hold_val = input} defaultValue={parseInt(props.item.item_qty_reserved)} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue7b">
                            <Form.Label title={props.item.components && props.item.components.length > 0 ? "This quantity includes order quantites for the item components":""}>On Order Qty {props.item.components && props.item.components.length > 0 ? "*" : ""}</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.on_order_qty} />
                        </Form.Group>

                    </Row>
                    {!props.item.is_regular && !props.item.newItem  && parseInt(props.item.item_qty) !=0 &&
                        <Row>
                        <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is not an e-commerce item, but has a stock quantity. This quantity is not reflected on any e-commerce website. </Form.Text>
                    </Row>
                    }

                    <Row>
                        <Form.Group as={Col} controlId="formValue11">
                            <Form.Label>Item Dimensions</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.item_depth ? `${props.item.item_depth} in x ${props.item.item_width} in x ${props.item.item_length} in` : ""} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue14">
                            <Form.Label>Item Weight</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.item_weight ? `${props.item.item_weight} lbs` : ""} />
                        </Form.Group>

                        {/*<Form.Group as={Col} controlId="formValue15">
                            <Form.Label>Shipping Method</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.shipping_method} />
                        </Form.Group>*/}
                        {ids && ids.length > 0 &&
                            <Form.Group as={Col} controlId="formValue14">
                                <Form.Label>Item IDs</Form.Label>
                                <Form.Select defaultValue={ids[0]} ref={(input) => main_id = input}>
                                    {ids.map((id, counter) =>
                                        <option key={`${id['id_type']}-${id['id_value']}-${counter}`} disabled={props.readOnly && counter != 0}> {id['id_primary'] ? "* " : ""} {id['id_type']}: {id['id_value']}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        }
                        <Form.Group as={Col} controlId="formValue10">
                            <Form.Label>Item Barcode</Form.Label>
                            <Form.Control ref={(input) => barcode_val = input} disabled={props.readOnly} type="string" placeholder="Barcode Value" defaultValue={props.item.item_barcode} />
                        </Form.Group>

                    </Row>
                    <Row>

                        <Form.Group as={Col} controlId="formValue125">
                            <Form.Label>Warehouse Location</Form.Label>
                            <Form.Control disabled={props.readOnly} ref={(input) => warehouse_loc = input} type="string" placeholder="Warehouse Location" defaultValue={props.item.item_warehouse_loc} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue126">
                            <Form.Label>Overstock Location</Form.Label>
                            <Form.Control disabled={props.readOnly} ref={(input) => overstock_loc = input} type="string" placeholder="Overstock Location" defaultValue={props.item.item_overstock_loc} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue16c">
                            <Form.Label>Vendor</Form.Label>
                            <Form.Control disabled={props.readOnly} ref={(input) => item_vendor = input} type="string" placeholder="Vendor" defaultValue={props.item.item_vendor} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue17">
                            <Form.Label>Purchase URL {props.item.item_url && props.item.item_url != "" && <a href={props.item.item_url} target="_blank"><FontAwesomeIcon className="trashIcon" icon={faExternalLinkAlt} title="Go To Purchase Page" /></a>}</Form.Label>
                            <Form.Control disabled={props.readOnly} ref={(input) => url_val = input} type="string" placeholder="Purchase URL" defaultValue={props.item.item_url} />
                        </Form.Group>
                    </Row>
                    <Row>
                  
                        <Form.Group as={Col} controlId="formValue16">
                            <Form.Label>FBA Case Qty</Form.Label>
                            <Form.Control disabled={props.readOnly || (props.item.components &&props.item.components.length>0)} ref={(input) => fba_case_qty = input} min="1" type="number" placeholder="FBA Order Multiple" defaultValue={props.item.fba_case_qty > 0 ? props.item.fba_case_qty : 1} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue16">
                            <Form.Label>Order Pack Qty</Form.Label>
                            <Form.Control disabled={props.readOnly || (props.item.components &&props.item.components.length>0)} ref={(input) => buy_pack_qty = input} min="1" type="number" placeholder="# in order pack" defaultValue={props.item.buy_pack_qty > 0 ? props.item.buy_pack_qty : 1} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue16b">
                            <Form.Label>Sold Pack Qty</Form.Label>
                            <Form.Control disabled={props.readOnly ||(props.item.components &&props.item.components.length>0)} ref={(input) => sale_pack_qty = input} min="1" type="number" placeholder="# in sale pack" defaultValue={props.item.sale_pack_qty > 0 ? props.item.sale_pack_qty : 1} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue16b">
                            <Form.Label title="Multiples the order quantity should be in. For example, if set to 3, parts should be ordered as 3,6,9, or 12, etc.">Order Multiple</Form.Label>
                            <Form.Control disabled={props.readOnly} ref={(input) => order_mutiple = input} min="1" type="number" placeholder="Order Multiple" defaultValue={props.item.buy_pack_multiple > 0 ? props.item.buy_pack_multiple : 1} />
                        </Form.Group>


                    </Row>
                    <Row style={{ padding: 10 }}>
                        <Form.Group as={Col} sm={12} style={{ padding: 0 }} controlId="formtags">
                            <Form.Label>Item Tags</Form.Label>
                            <Tags disabled={props.readOnly} suggestTags={props.allTags} tags={tags} setTags={setTags} onChange={(new_tags) => setTags(new_tags)} />
                        </Form.Group>

                    </Row>
                    <Row style={{ paddingLeft: 5 }}>
                        <Form.Group as={Col} sm={3} controlId="formValueDNR">
                            <IndeterminateCheckbox disabled={props.readOnly} onChange={(e) => {
                                if (e.target.checked) {
                                    setDnrGroupSum(2);
                                    dnr_ecom.current.checked = true
                                    dnr_fba.current.checked = true
                                } else {
                                    setDnrGroupSum(0)
                                    dnr_ecom.current.checked = false
                                    dnr_fba.current.checked = false
                                }

                            }} label="Do Not Reorder" name="DNR All" type="checkbox" id="DNRALL" value={dnrGroupSum} />
                            <Form.Check style={{ marginLeft: 10 }} disabled={props.readOnly || dnrGroupSum == 0} onChange={() => setDnrGroupSum(dnr_ecom.current.checked + dnr_fba.current.checked)} ref={dnr_ecom} defaultChecked={props.item.dnr_ecom} label="DNR for E-commerce" name="DNR Ecommerce" type="checkbox" id="DNRECOM" />
                            <Form.Check style={{ marginLeft: 10 }} disabled={props.readOnly || dnrGroupSum == 0} onChange={() => setDnrGroupSum(dnr_ecom.current.checked + dnr_fba.current.checked)} ref={dnr_fba} defaultChecked={props.item.dnr_fba} label="DNR for FBA" name="DNR FBA" type="checkbox" id="DNRFBA" />
                            <Form.Check disabled={props.readOnly} onChange={(e) => setShowHideMessage(e.target.checked + 1)} ref={hide_ref} defaultChecked={props.item.hide_until && props.item.hide_until != ""} label="Hide From Profit Warnings" name="profit_hide" type="checkbox" id="PROFITHIDE" />

                            {showHideMessage && props.item.hide_until == "" ? <Form.Label style={{ marginLeft: 30 }}><em><small>{showHideMessage == 2 ? "Item will be hidden for 4 weeks" : "Item will no longer be hidden"}</small></em></Form.Label> : <></>}
                            {props.item.hide_until && props.item.hide_until != "" ? <Form.Label style={{ marginLeft: 30 }}><em><small>{props.item.hide_until == "TRUE" ? "Hidden for 4 weeks" : `Hidden Until ${props.item.hide_until}`}</small></em></Form.Label> : <></>}
                        </Form.Group>

                        <Form.Group as={Col} sm={9} controlId="formValue18">
                            <Form.Label>Item Notes</Form.Label>
                            <Form.Control disabled={props.readOnly} size="sm" as="textarea" maxLength="512" rows={4} ref={(input) => notes_val = input} type="string" placeholder="Item Notes" defaultValue={props.item.item_notes} />
                        </Form.Group>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                { (props.item.item_type === "" || props.item.newItem) &&props.item.item_name != "" && <Button onClick={onDelete}>Delete</Button>}
                <Button onClick={props.onHide}>Cancel</Button>
                <Button type="submit" disabled={props.readOnly}>Save</Button>
            </Modal.Footer>
            </Form>
        </div>


    )
}