

export const restockStatusToColor = (status) => {
  var background = "info"
  if (status == "Order Soon") {
    background = "warning"
  } else if (status == "Move to FBA") {
    background = "primary"
  } else if (status == "Good Stock") {
    background = "success"
  } else if (status == "Order Now") {
    background = "danger"
  } else if (status == "Overstock") {
    background = "secondary"
  } else if (status == "Low, On Order") {
    background = "dark"
  }
  return background
}

export const restockStatusToTextColor = (status) => {
  var background = "light"
  if (status == "Order Soon") {
    background = "dark"
  } else if (status == "FBA No Ship") {
    background = "dark"
  }
  return background
}


const roundUp = (numToRound, multiple) => {
  if (multiple == 0)
    return numToRound;

  var remainder = numToRound % multiple;
  if (remainder == 0)
    return numToRound;

  return numToRound + multiple - remainder;
}

export const NO_STATUS = 0
export const ORDER_NOW = 1
export const MOVE_TO_FBA = 2
export const ORDER_SOON = 4
export const LOW_ON_ORDER = 8
export const GOOD_STOCK = 16
export const OUT_OF_STOCK = 32
export const OVERSTOCK = 64
export const SEND_TO_FBA = 128
export const DNR_FBA = 512
export const DNR = 1024


//Funko Five Nights at Freddy's Foxy Plush, 6"
export const setRestockValues = (row) => {
  var stock_weeks = row.item_stock_weeks > 0 ? row.item_stock_weeks : 30
  var lead_time = parseInt(row.item_lead_time) != 0 ? Math.abs(parseInt(row.item_lead_time)) : 10
  var itemsNeededForDays30 = stock_weeks * row.avg_weekly_store_sales_30
  var itemsNeededForFBADays30 = stock_weeks * row.avg_weekly_fba_sales_30

  var itemsNeededForDays60 = stock_weeks * row.avg_weekly_store_sales_60
  var itemsNeededForFBADays60 = stock_weeks * row.avg_weekly_fba_sales_60

  //this is max amount sold per day
  var maxSales = Math.max(row.avg_weekly_store_sales_30, row.avg_weekly_store_sales_60)
  var maxSalesFBA = Math.max(row.avg_weekly_fba_sales_30, row.avg_weekly_fba_sales_60)

  //these are how many we need to reach our needed stock
  var maxItemsNeeded = Math.max(itemsNeededForDays30, itemsNeededForDays60)
  var maxItemsNeededFBA = Math.max(itemsNeededForFBADays30, itemsNeededForFBADays60)

  var daysSupplyLeftStock = maxSales == 0 ? 1000 : row.item_qty / maxSales
  var daysSupplyLeftFBA = maxSalesFBA == 0 ? 1000 : (row.fba_item_qty + row.item_qty_reserved) / maxSalesFBA
  var daysSupplyLeftFBANoHold = maxSalesFBA == 0 ? 1000 : (row.fba_item_qty) / maxSalesFBA
  var fbaOrder = 0
  var ecomOrder = 0
  var isStock = row.is_regular
  var isFBA = row.is_fba
  row.to_order = 0
  row.status = ["Unknown"]
  row.status_num = NO_STATUS
  row.reason = ""

  var dnr_ecom = row.dnr_ecom
  var dnr_fba = row.dnr_fba

  var buy_pack_multiple = row.buy_pack_multiple
  if (row.item_qty + row.item_qty_reserved + row.fba_item_qty == 0) {
    row.status_num = OUT_OF_STOCK
    row.reason = ""

  }
  if (row.status_num == OUT_OF_STOCK && itemsNeededForDays30 == 0 && itemsNeededForFBADays30 == 0 && itemsNeededForDays60 == 0 && itemsNeededForFBADays60 == 0) {

    if (dnr_ecom && dnr_fba) {
      row.status = ["DNR"]
      row.status_num |= DNR
      row.reason = "This item is marked as DNR for both E-commerce and FBA."
      row.to_order = 0
    } else if (dnr_ecom) {
      row.status = ["DNR"]
      row.status_num |= (DNR | OUT_OF_STOCK)
      row.reason = "This item is marked as DNR for E-commerce, and has no FBA sales in the past 60 days. As such, no FBA restock values can be estimated."
      row.to_order = 0
    } else {
      row.status = ["Out Of Stock"]
      row.reason = "This item is out of stock, and has no sales in the past 60 days. As such, no restock values can be estimated."
    }
  } else if (((!isStock && !dnr_fba) || (dnr_ecom && !dnr_fba) || (isStock && daysSupplyLeftStock - lead_time > Math.round(stock_weeks * 2)))
    && ((!isFBA && !dnr_ecom) || (dnr_fba && !dnr_ecom) || (isFBA && daysSupplyLeftFBA - lead_time > Math.round(stock_weeks * 2))) && !(dnr_ecom && dnr_fba)) {
    row.to_order = 0
    row.status = ["Overstock"]
    row.status_num |= OVERSTOCK
    row.reason = "With current inventory, there are "
    if (isStock) {
      row.reason += (daysSupplyLeftStock > 300 ? "more than 300" : Math.round(daysSupplyLeftStock)) + " days of supply left for E-commerce sales"

    } if (isStock && isFBA) {
      row.reason += ", and "
    }
    if (isFBA) {
      row.reason += Math.round(daysSupplyLeftFBA) + " days of supply left for FBA sales"
    }
    row.reason += ". With factoring a lead time of " + lead_time + " days, there is enough stock to last more than double the requested stock days of " + stock_weeks + " days."
  } else if (((!isStock && !dnr_fba) || (dnr_ecom && !dnr_fba) || (isStock && daysSupplyLeftStock - lead_time > Math.round(stock_weeks))) && ((!isFBA && !dnr_ecom) || (dnr_fba && !dnr_ecom) || (isFBA && daysSupplyLeftFBA - lead_time > Math.round(stock_weeks))) && !(dnr_ecom && dnr_fba)) {
    row.to_order = 0
    row.status = ["Good Stock"]
    row.status_num |= GOOD_STOCK

    row.reason = "With current inventory, there are "
    if (isStock) {
      row.reason += (daysSupplyLeftStock > 300 ? "more than 300" : Math.round(daysSupplyLeftStock)) + " days of supply left for E-commerce sales"

    } if (isStock && isFBA) {
      row.reason += ", and "
    }
    if (isFBA) {
      row.reason += Math.round(daysSupplyLeftFBA) + " days of supply left for FBA sales"
    }
    row.reason += ". With factoring a lead time of " + lead_time + " days, there is enough stock to last more than  the requested stock days of " + stock_weeks + " days."



  } else if (((!isStock && !dnr_fba) || (dnr_ecom && !dnr_fba) || (isStock && daysSupplyLeftStock - lead_time > Math.round(stock_weeks * .85))) && ((!isFBA && !dnr_ecom) || (dnr_fba && !dnr_ecom) || (isFBA && daysSupplyLeftFBA - lead_time > Math.round(stock_weeks * .85))) && !(dnr_ecom && dnr_fba)) {
    row.to_order = 0
    row.status = ["Order Soon"]
    row.status_num |= ORDER_SOON

    row.reason = "With current inventory, there are "
    if (isStock) {
      row.reason += (daysSupplyLeftStock > 300 ? "more than 300" : Math.round(daysSupplyLeftStock)) + " days of supply left for E-commerce sales"

    } if (isStock && isFBA) {
      row.reason += ", and "
    }
    if (isFBA) {
      row.reason += Math.round(daysSupplyLeftFBA) + " days of supply left for FBA sales"
    }
    row.reason += ". With factoring a lead time of " + lead_time + " days, there is enough stock to last only " + stock_weeks + " days, requiring this item to be ordered soon."


  } else if ((isStock && daysSupplyLeftStock - lead_time > Math.round(stock_weeks * 1.25)) && (isFBA && !dnr_fba && daysSupplyLeftFBA - lead_time < Math.round(stock_weeks * .5)) && row.item_qty > maxItemsNeededFBA) {
    row.to_order = 0
    row.status = ["Move to FBA"]
    row.status_num |= MOVE_TO_FBA

    row.reason = "With current inventory, there are "
    if (isStock) {
      row.reason += (daysSupplyLeftStock > 300 ? "more than 300" : Math.round(daysSupplyLeftStock)) + " days of supply left for E-commerce sales"

    } if (isStock && isFBA) {
      row.reason += ", and "
    }
    if (isFBA) {
      row.reason += Math.round(daysSupplyLeftFBA) + " days of supply left for FBA sales"
    }
    row.reason += ". With factoring a lead time of " + lead_time + " days, there is overstock for E-commerce sales, but not enough stock listed for FBA. It is advised to move some items set aside for E-Commerce stock to FBA."


  } else {

    if (dnr_ecom && dnr_fba) {
      row.status = ["DNR"]
      row.status_num |= DNR
      row.reason = "This item is marked as DNR for both E-commerce and FBA."
      row.to_order = 0
    } else {

      var dnr_reason = ""
      row.reason = "With current inventory, there are "

      if (isStock) {
        row.reason += (daysSupplyLeftStock > 300 ? "more than 300" : Math.round(daysSupplyLeftStock)) + " days of supply left for E-commerce sales"

      } if (isStock && isFBA) {
        row.reason += ", and "
      }
      if (isFBA) {
        row.reason += Math.round(daysSupplyLeftFBA) + " days of supply left for FBA sales"
      }




      var former = false
      if (dnr_ecom) {
        if (dnr_fba) {
          row.status = ["DNR"]
        }
        row.status_num |= DNR
        dnr_reason = "This item is marked as DNR for E-commerce. "
        row.to_order = 0
      } else if (isStock && daysSupplyLeftStock - lead_time <= Math.round(stock_weeks * .85)) {

        ecomOrder = Math.ceil(Math.max(0, (maxItemsNeeded - row.item_qty) + (lead_time * maxSales)))
        row.to_order = ecomOrder



        row.reason += ". With factoring a lead time of " + lead_time + " days, "
        if (ecomOrder >= 1) {
          row.status = ["Order Now"]
          row.status_num |= 1

          row.reason += "there is not enough inventory for E-Commerce sales"
        } else {
          row.reason += "because of limited sales on E-Commerce sites, there is enough E-Commerce inventory"
        }
        former = true


      }
      if (dnr_fba) {
        if (dnr_ecom) {
          row.status = ["DNR"]
        }
        row.status_num |= DNR
        dnr_reason += "This item is marked as DNR for FBA. "
        row.to_order = 0
      } else if (isFBA && daysSupplyLeftFBA - lead_time <= Math.round(stock_weeks * .85)) {
        fbaOrder = roundUp(Math.ceil(Math.max(0, (maxItemsNeededFBA - (row.fba_item_qty + row.item_qty_reserved)) + lead_time * maxSalesFBA)), row.fba_case_qty)
        row.to_order += fbaOrder


        if (!former) {
          row.reason += ". With factoring a lead time of " + lead_time + " days, "
        }
        if (fbaOrder >= 1) {
          row.status = ["Order Now"]
          row.status_num |= ORDER_NOW

          if (former) {
            row.reason += " and not enough inventory for FBA sales"
          } else {
            row.reason += "there is not enough inventory for FBA sales"
          }
        } else {
          if (former) {
            row.reason += " and because of limited sales on FBA, there is enough FBA inventory"
          } else {
            row.reason += "because of limited sales on FBA, there is enough FBA inventory"
          }
        }
      }
      row.reason += "."
      if (dnr_reason !== "") {
        row.reason += " However, t" + dnr_reason.substring(1)
      }
    }
  }


  row.to_order -= row.on_order_qty
  row.to_order = Math.max(0, Math.round(row.to_order))

  if ("Alliance 02003 Bands Green (DNR)" === row.item_sku) {
    console.log(row)
  }


  if (row.dnr_fba && !row.dnr_ecom) {
    row.status = [...row.status, "FBA No Ship"]
    row.status_num |= DNR_FBA
    fbaOrder = 0
  }



  ecomOrder = ecomOrder - row.ecom_on_order_qty
  fbaOrder -= row.fba_on_order_qty



  ecomOrder = Math.max(0, Math.round(ecomOrder))
  fbaOrder = Math.max(0, Math.round(fbaOrder))

  fbaOrder = roundUp(fbaOrder, row.fba_case_qty)


  row.to_order = fbaOrder + ecomOrder

  var fudge = 0
  if (row.to_order > 0) {
    if (buy_pack_multiple !== 1) {
      if (row.to_order % buy_pack_multiple !== 0) {
        var old_val = row.to_order
        row.to_order = Math.ceil(row.to_order / buy_pack_multiple) * buy_pack_multiple
        fudge = row.to_order - old_val
      }
    } else {
      var old_val = row.to_order
      if (row.to_order <= 10) {
        //nop
      } else if (row.to_order < 12 && row.to_order > 10) {
        row.to_order = 10
        fudge = row.to_order - old_val
      } else if (row.to_order < 17) {
        row.to_order = 15
        fudge = row.to_order - old_val
      } else if (row.to_order < 50) {
        row.to_order = roundUp(row.to_order, 5)
        fudge = row.to_order - old_val
      } else {
        row.to_order = roundUp(row.to_order, 10)
        fudge = row.to_order - old_val
      }
    }
  }

  if (ecomOrder > 0) {
    ecomOrder += fudge
  } else if (fbaOrder > 0) {
    fbaOrder += fudge
  }

  if (row.to_order <= 0 && row.on_order_qty > 0 && (row.status_num & 1)) {
    row.status = ["Low, On Order"]
    row.status_num |= LOW_ON_ORDER
    row.reason += " However, there are currently " + row.on_order_qty + " on order, which when the units come in, will maintain enough inventory."
  }
  if (row.to_order < 0) {
    row.to_order = 0
  }
  if (row.to_order <= 0 && row.status_num == 1) {
    row.status = ["Good Stock"]
    row.status_num |= GOOD_STOCK
  }


  var sendToFBA = 0
  var date1 = new Date(row.fba_start);
  var date2 = Date.now()

  // To calculate the time difference of two dates 
  var Difference_In_Time = date2 - date1.getTime();


  // To calculate the no. of days between two dates 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  if (isFBA && !dnr_fba && (daysSupplyLeftFBANoHold - 5 < Math.round(stock_weeks * .9) || (Difference_In_Days < 15 && row.item_qty_reserved))) {
    if (Difference_In_Days < 15 && row.item_qty_reserved) {
      row.to_send = row.item_qty_reserved

    } else {
      row.to_send = (maxSalesFBA * 5 + maxItemsNeededFBA) - row.fba_item_qty
    }
    if (row.to_send > row.item_qty_reserved) {
      row.to_send = row.item_qty_reserved
    }

    //dont leave less than 5
    if (row.item_qty_reserved - row.to_send < 5) {
      row.to_send = row.item_qty_reserved
    }

    //round up to the send multiple or nearest multiple of 5
    if (row.to_send != row.item_qty_reserved) {
      row.to_send = roundUp(row.to_send, row.fba_case_qty > 1 ? row.fba_case_qty : 5)
    }

    //if after rounding up, and are greater than we have, cap it.
    if (row.to_send > row.item_qty_reserved) {
      row.to_send = row.item_qty_reserved
    }

    if (Math.round(row.to_send) > 15 && row.item_qty_reserved > 0) {
      row.status = [...row.status, "Send " + Math.min(row.item_qty_reserved, roundUp(Math.round(row.to_send), row.fba_case_qty)) + " To FBA"]
      row.status_num |= SEND_TO_FBA
    }
  }


  return { order: row.to_order, ecom_order: ecomOrder, fba_order: fbaOrder, status: row.status, days_left: Math.round(Math.min(daysSupplyLeftStock, daysSupplyLeftFBA)), status_num: row.status_num, status_reason: row.reason, to_send: row.to_send }
}
